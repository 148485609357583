/* You can add global styles to this file, and also import other style files */
body {
    margin: 0 !important;
    padding: 0 !important;
    background-color: #171717;
}

p, button, h1, h2, h3, h4 {
    font-family: var(--primary-font-family);
    color: var(--primary-font-color);
    margin: 0;
}

h2 {
    font-size: 21px;
    text-align: center;
    color: var(--primary-font-color);
    font-weight: 500;
    margin: 0;
}

h3 {
    font-size: 19px;
    text-align: center;
}

p {
    margin-top: 5px;
    margin-bottom: 5px;
}

button {
    background-color: var(--primary-button-background-color);
    color:  var(--primary-button-color);
    font-family: var(--primary-font-family);
    border: transparent;
    padding: 17px;
    font-weight: 700;
}

button:hover {
    cursor: pointer;
}

button:disabled {
    background: transparent;
    border: 1px solid var(--primary-button-background-color);
    color: var(--primary-font-color);
    opacity: 0.2;
}

.box {
    background-color: var(--box-background-color);
    border: var(--box-border-thickness) solid var(--box-border-color);
    border-radius: var(--box-corner-radius);
}

.strong-words {
    color: var(--secondary-font-color);
}

.question {
    text-align: center;
    font-weight: 500;
    font-size: var(--question-font-size);
    font-family: var(--primary-font-family);
    color: var(--primary-font-color);
}

.question-number {
    font-weight: bold;
    font-size: var(--title-font-size);
    color: var(--secondary-font-color);
}

.question-number::first-letter {
    color: var(--tertiary-font-color);
}

